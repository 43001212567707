import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Sculpture = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>submit: a kind of sculpture (project A)</h2>
        <p>Brief: <Link to="../sculpture">Project A</Link></p>

        <br></br>
        <p>Please do the following to submit your project:</p>
        <p>1. Add a README.md file to your project folder. In this file, using <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet">markdown</a>, briefly describe your project and <a target="_blank" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet#images">add a well taken screenshot</a>. Share challenges you faced, solutions (if applicable), compromises and shortcomings of your project. (<a href="https://github.com/yz3440/my-cdv-ss20/tree/master/my-work/week13/datastory-outline" target="_blank">README Example</a> by Yufeng)</p>
        <p>2. Make sure your project looks good in various browser sizes.</p>
        <p>3. Push your project to your repository and link to it from your room page.</p>
        <p>4. Be prepared to introduce your project next class, share challenges you faced, solutions (if applicable), compromises and shortcomings of your project.</p>
      </MiniLayout>
    </Layout>
  )
}



export default Sculpture
